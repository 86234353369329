import { useWeb3React } from '@web3-react/core';
import { useEffect, useState } from 'react';
import { truncateWalletString } from 'utils';
import ConnectModal from '../connectModal/ConnectModal';
import { Link } from 'react-router-dom';
import './bearTopbar.scss'
import {HashLink} from 'react-router-hash-link'
type MenuType = {
    menuOpen?: boolean;
    setMenuOpen(flag: boolean): void;
};
export default function BearTopbar({ menuOpen, setMenuOpen }: MenuType) {
    const [showConnectModal, setShowConnectModal] = useState(false);
    const [loginStatus, setLoginStatus] = useState(false);
    const { connector, library, chainId, account, active } = useWeb3React();
    useEffect(() => {
        const isLoggedin = account && active && chainId === parseInt(process.env.REACT_APP_NETWORK_ID, 10);
        setLoginStatus(isLoggedin);
    }, [connector, library, account, active, chainId]);

    return (
        <div className="bearTopbar">
            <div className="logo">
                <Link to="/#home" ><img src="assets/loadingImg.png" alt="" /></Link>
            </div>
            <div className="navList">
                <ul>
                    <li><HashLink to="#bearHome">Home</HashLink></li>
                    <li><HashLink to="/#about">About</HashLink></li>
                    <li><HashLink to="/#volunteer">Volunteer</HashLink></li>
                    <li><HashLink to="/#causes">Causes</HashLink></li>
                    <li><HashLink to="/#blog">Blog</HashLink></li>
                    <li><HashLink to="/#contact">Contact</HashLink></li>
                </ul>
                
            </div>
            <div className="btns">
            {loginStatus ? 
                <button>{truncateWalletString(account)}</button>:
                <button onClick={() => { setShowConnectModal(true) }}>CONNECT WALLET</button>
            }
            </div>
            
            <div className={(menuOpen ? "hamburger active" : "hamburger")} onClick={() => setMenuOpen(!menuOpen)}>
                <span className="line1"></span>
                <span className="line2"></span>
                <span className="line3"></span>
            </div>
            <ConnectModal showConnectModal={showConnectModal} setShowConnectModal={setShowConnectModal} />
        </div>
    )
}
