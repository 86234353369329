import { useWeb3React } from '@web3-react/core';
import { useEffect, useState } from 'react';
import { truncateWalletString } from 'utils';
import {HashLink} from 'react-router-hash-link'
import ConnectModal from '../connectModal/ConnectModal';
import { Link } from 'react-router-dom';
import './salmonTopbar.scss'

type MenuType = {
    menuOpen?: boolean;
    setMenuOpen(flag: boolean): void;
};
export default function SalmonTopbar({ menuOpen, setMenuOpen }: MenuType) {
    const [showConnectModal, setShowConnectModal] = useState(false);

    const [loginStatus, setLoginStatus] = useState(false);
    const { connector, library, chainId, account, active } = useWeb3React();
    useEffect(() => {
        const isLoggedin = account && active && chainId === parseInt(process.env.REACT_APP_NETWORK_ID, 10);
        setLoginStatus(isLoggedin);
    }, [connector, library, account, active, chainId]);

    return (
        <div className="salmonTopbar">
            <div className="logo">
                <HashLink to="/#home" ><img src="assets/loadingImg.png" alt="" /></HashLink>
            </div>
            <div className="navList">
                <ul>
                    <li><HashLink to="#salmonHome" smooth>Home</HashLink></li>
                    <li><HashLink to="/#about" smooth>About</HashLink></li>
                    <li><HashLink to="/#volunteer" smooth>Volunteer</HashLink></li>
                    <li><HashLink to="/#causes" smooth>Causes</HashLink></li>
                    <li><HashLink to="/#blog" smooth>Blog</HashLink></li>
                    <li><HashLink to="/#contact" smooth>Contact</HashLink></li>
                </ul>
            </div>
            {loginStatus ? 
                <button>{truncateWalletString(account)}</button>:
                <button onClick={() => { setShowConnectModal(true) }}>connect wallet</button>
            }
            <div className={(menuOpen ? "hamburger active" : "hamburger")} onClick={() => setMenuOpen(!menuOpen)}>
                <span className="line1"></span>
                <span className="line2"></span>
                <span className="line3"></span>
            </div>
            <ConnectModal showConnectModal={showConnectModal} setShowConnectModal={setShowConnectModal} />
        </div>
    )
}
