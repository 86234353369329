import About from 'components/about/About';
import Footer from 'components/footer/Footer';
import Home from 'components/home/Home';
import Loading from 'components/loading/Loading';
import Menu from 'components/menu/Menu';
import Topbar from 'components/topbar/Topbar';
import { useEagerConnect } from 'hooks/useEagerConnect';
import { useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { useMediaQuery } from 'react-responsive';
import './homePage.scss'
import Active from 'components/active/Active';
import Volunteer from 'components/volunteer/Volunteer';

export default function HomePage() {
    useEagerConnect();
    const [isHomeLoading, setIsHomeLoading] = useState(true);
    // const [isAboutLoading, setIsAboutLoading] = useState(true);
    const [isActiveLoading, setIsActiveLoading] = useState(true);
    const [isVolunteerLoading, setIsVolunteerLoading] = useState(true);

    const [sectionWidth, setSectionWidth] = useState(0);
    const [sectionHeight, setSectionHeight] = useState("0vh");
    const [loadingWidth, setLoadingWidth] = useState(0);
    const [loadingHeight, setLoadingHeight] = useState(0);

    const [menuOpen, setMenuOpen] = useState(false);
    const isTabletOrMobile = useMediaQuery({
        query: "screen and (max-width: 768px) and (orientation:portrait)",
      });
      const isLandOrMobile = useMediaQuery({
        query: "screen and (max-height: 768px) and (orientation:landscape)",
      });
      window.onload = function () {
        console.log('ok')
      };

    useEffect(() => {
        if (isHomeLoading || isActiveLoading || isVolunteerLoading) {
            setLoadingWidth(100)
            setLoadingHeight(100)

            setSectionWidth(0);
            setSectionHeight("0vh")
        }
        else{
            setLoadingWidth(0)
            setLoadingHeight(0)

            setSectionWidth(100);
            setSectionHeight("auto")
        }
        if (isTabletOrMobile) {
        }
    
        if (isLandOrMobile) {
        }
        if (!isLandOrMobile && !isTabletOrMobile) {
            setMenuOpen(false);
        }

    }, [isHomeLoading, isActiveLoading, isVolunteerLoading,  isTabletOrMobile, isLandOrMobile ]);
    
    return (
        <>
            <Toaster
                position="top-center"
                toastOptions={{
                success: { duration: 3000 },
                error: { duration: 3000 },
                }}
            />
            {loadingWidth === 0 &&<Topbar menuOpen = {menuOpen} setMenuOpen = {setMenuOpen}/>}
            <Menu menuOpen = {menuOpen} setMenuOpen = {setMenuOpen}/>
            <div className='homePage'>
                <div className="loding" style = {{width: loadingWidth + "%", height: loadingHeight + "vh", display: loadingWidth === 0? 'none':'flex'}}>
                    <Loading/>
                </div>
                <div className="sections" style = {{width: sectionWidth + "%", height: sectionHeight, backgroundImage:'url("assets/snowBG.png")'}}>
                    <Home setIsLoading = {setIsHomeLoading}/>
                    <About/>
                    <Active setIsLoading = {setIsActiveLoading}/>
                    <Volunteer  setIsLoading = {setIsVolunteerLoading}/>
                    <Footer />
                </div>
            </div>
        </>
    )
}
