import './bearSections.scss'
import { Link } from 'react-router-dom';
import Fade  from "react-reveal/Fade"

type LoadingType = {
    setIsLoading(flag:boolean):void;
};
export default function BearSections({setIsLoading}:LoadingType) {
    const handleImageLoaded = ()=>{
        setIsLoading(false);
    }
    return (
        <div className="bearSections" id = "bearSections" >
            <div className="bearSectionsContent">
                <div className="wrapper">
                    <Fade left>
                        <div className="img">
                            <img src="assets/Image 1.png"  onLoad={handleImageLoaded} alt="" />
                        </div>
                    </Fade>
                    <Fade right>
                        <div className="text">
                            <h1>This Must Change.</h1>
                            <p><span>67%</span> of 13-25 year olds believe the pandemic will have a long-term negative effect on their mental health. </p>
                            <p>“When the 17-year-olds (gathered by the Millennium Cohort Study) were asked if they had ever hurt themselves “on purpose in an attempt to end your life”, <span>7% replied yes</span>. When asked if they had self-harmed during the previous year, <span>24% responded that they had.”</span></p>
                            <p>Of the 922 suicides in under 25’s last year, a staggering <span>76%</span> were male. </p>

                        </div>
                    </Fade>
                </div>
                <div className="wrapper">
                    <Fade left>
                        <div className="text">
                            <h1>What’s HeadStrong?</h1>
                            <p>A vastly specialized team of workers from within the education sector for ‘hard-to-handle’ youth came together and formed a plan to make a change and put a stop to suicides in male teens and young adults.</p>
                            <p>HeadStrong is starting a youth focused gym project building towards a bigger dream known as ‘Youth City’. Young people, aged between 13-18, are invited to join the gym to become healthier and stronger through powerlifting, bodybuilding and martial arts as well as digital economic classes, life coaching, and mental support.</p>
                            <p>HeadStrong students will be learning to rely on each other and grow as a community of young people who look out for each other, becoming stronger as part of a ‘family’. Strong in their bodies, strong in their thinking and strong in their connection.</p>

                        </div>
                    </Fade>
                    <Fade right>
                        <div className="img">
                            <img src="assets/Image 3.png" alt="" />
                        </div>
                    </Fade>
                </div>
                <div className="wrapper">
                    <Fade left>
                        <div className="img">
                            <img src="assets/Image 4.png"  onLoad={handleImageLoaded}  alt="" />
                        </div>
                    </Fade>
                    <Fade right>
                        <div className="text">
                            <h1>HeadStrong’s aims, AKA-our four C’s</h1>
                            <p><span>CORE</span> - Building CORE strength. Physical strength and ability carries over to mental well-being, feeling able, growing and being positive.</p>
                            <p><span>COMMUNITY</span> - Building COMMUNITY. Brotherhood/Sisters/family – a sense of belonging without judgment. Working and growing together.</p>
                            <p><span>CONFIDENCE</span> - Feeling a greater all-around CONFIDENCE, but specifically an increased confidence in speaking (up), communication and athleticism. </p>
                            <p><span>CAPACITY</span> - Increasing CAPACITY in growing resilience and the mental capacity to overcome adversity.</p>
                        </div>
                    </Fade>
                </div>
            </div>

            <div className="bearSectionsContent_mob">
                <div className="wrapper">
                    <Fade left>
                        <div className="img">
                            <h1>This Must Change.</h1>
                            <img src="assets/Image 1.png"  onLoad={handleImageLoaded} alt="" />
                        </div>
                    </Fade>
                    <Fade right>
                        <div className="text">
                            <p><span>67%</span> of 13-25 year olds believe the pandemic will have a long-term negative effect on their mental health. </p>
                            <p>“When the 17-year-olds (gathered by the Millennium Cohort Study) were asked if they had ever hurt themselves “on purpose in an attempt to end your life”, <span>7% replied yes</span>. When asked if they had self-harmed during the previous year, <span>24% responded that they had.”</span></p>
                            <p>Of the 922 suicides in under 25’s last year, a staggering <span>76%</span> were male. </p>

                        </div>
                    </Fade>
                </div>
                <div className="wrapper">
                    <Fade left>
                        <div className="img">
                            <h1>What’s HeadStrong?</h1>
                            <img src="assets/Image 3.png" alt="" />
                        </div>
                    </Fade>
                    <div className="text">
                            <p>A vastly specialized team of workers from within the education sector for ‘hard-to-handle’ youth came together and formed a plan to make a change and put a stop to suicides in male teens and young adults.</p>
                            <p>HeadStrong is starting a youth focused gym project building towards a bigger dream known as ‘Youth City’. Young people, aged between 13-18, are invited to join the gym to become healthier and stronger through powerlifting, bodybuilding and martial arts as well as digital economic classes, life coaching, and mental support.</p>
                            <p>HeadStrong students will be learning to rely on each other and grow as a community of young people who look out for each other, becoming stronger as part of a ‘family’. Strong in their bodies, strong in their thinking and strong in their connection.</p>
                        </div>
                    <Fade right>
                        
                    </Fade>
                </div>
                <div className="wrapper">
                    <Fade left>
                        <div className="img">
                            <h1>HeadStrong’s aims, AKA-our four C’s</h1>
                            <img src="assets/Image 4.png" alt="" />
                        </div>
                    </Fade>
                    <Fade right>
                        <div className="text">
                            <p><span>CORE</span> - Building CORE strength. Physical strength and ability carries over to mental well-being, feeling able, growing and being positive.</p>
                            <p><span>COMMUNITY</span> - Building COMMUNITY. Brotherhood/Sisters/family – a sense of belonging without judgment. Working and growing together.</p>
                            <p><span>CONFIDENCE</span> - Feeling a greater all-around CONFIDENCE, but specifically an increased confidence in speaking (up), communication and athleticism. </p>
                            <p><span>CAPACITY</span> - Increasing CAPACITY in growing resilience and the mental capacity to overcome adversity.</p>
                        </div>
                    </Fade>
                </div>
            </div>
        </div>
    )
}
