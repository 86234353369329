import './footer.scss'

export default function Footer() {
    return (
        <div className="footer">
            
            <div className="footerContent">
                <div className="socialLinks">
                    {/* <a href="/" target="_blank"><i className="fab fa-twitter" /></a> 
                    <a href="/" target="_blank"><i className="fab fa-telegram"/></a> 
                    <a href="/" target="_blank"><i className="fab fa-instagram"></i></a>
                    <a href="/" target="_blank"><i className="fab fa-discord"></i></a>  */}
                    <div><i className="fab fa-twitter" /></div> 
                    <div><i className="fab fa-telegram"/></div> 
                    <div><i className="fab fa-instagram"></i></div>
                    <div><i className="fab fa-discord"></i></div> 
                </div>
                <p>Copyright © <span>Sachiko Group</span>, 2022</p>
            </div>
        </div>
    )
}
