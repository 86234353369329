import { useWeb3React } from '@web3-react/core';
import Footer from 'components/footer/Footer';
import Loading from 'components/loading/Loading';
import Menu from 'components/menu/Menu'
import SalmonHome from 'components/salmonHome/SalmonHome';
import SalmonMint from 'components/salmonMint/SalmonMint';
import SalmonSections from 'components/salmonSections/SalmonSections';
import SalmonTopbar from 'components/salmonTopbar/SalmonTopbar';
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import './salmonPage.scss'

export default function SalmonPage() {
    const [menuOpen, setMenuOpen] = useState(false);
    const [isSalmonHomeLoading, setIsSalmonHomeLoading] = useState(true);
    const [isSalmonSectionsLoading, setIsSalmonSectionsLoading] = useState(true);
    const [isSalmonMintLoading, setIsSalmonMintLoading] = useState(false);

    const isTabletOrMobile = useMediaQuery({
        query: "screen and (max-width: 768px) and (orientation:portrait)",
      });
      const isLandOrMobile = useMediaQuery({
        query: "screen and (max-height: 768px) and (orientation:landscape)",
      });
    const [sectionWidth, setSectionWidth] = useState(0);
    const [sectionHeight, setSectionHeight] = useState("0vh");
    const [loadingWidth, setLoadingWidth] = useState(0);
    const [loadingHeight, setLoadingHeight] = useState(0);

    useEffect(() => {
        if (isSalmonHomeLoading || isSalmonSectionsLoading || isSalmonMintLoading ) {
            setLoadingWidth(100)
            setLoadingHeight(100)

            setSectionWidth(0);
            setSectionHeight("0vh")
        }
        else{
            setLoadingWidth(0)
            setLoadingHeight(0)

            setSectionWidth(100);
            setSectionHeight("auto")
        }
        // menu effect
        if (isTabletOrMobile) {
        }
    
        if (isLandOrMobile) {
        }
        if (!isLandOrMobile && !isTabletOrMobile) {
            setMenuOpen(false);
        }

    }, [isSalmonHomeLoading, isSalmonSectionsLoading, isSalmonMintLoading, isTabletOrMobile, isLandOrMobile ]);
    return (
        <div className='salmonPage'>
            {loadingWidth === 0 &&<SalmonTopbar menuOpen = {menuOpen} setMenuOpen = {setMenuOpen}/>}
            <Menu menuOpen = {menuOpen} setMenuOpen = {setMenuOpen}/>
            <div className="loding" style = {{width: loadingWidth + "%", height: loadingHeight + "vh", display: loadingWidth === 0? 'none':'flex'}}>
                <Loading/>
            </div>
            <div className="sections" style = {{width: sectionWidth + "%", height: sectionHeight, backgroundImage:'url("assets/snowBG.png")'}}>
                <SalmonHome setIsLoading = {setIsSalmonHomeLoading}/>
                <SalmonSections setIsLoading = {setIsSalmonSectionsLoading}/>
                <SalmonMint setIsLoading = {setIsSalmonMintLoading}/>
                <Footer/>
            </div>
        </div>
    )
}
