import { Link } from 'react-router-dom'
import './volunteer.scss'
import Fade  from "react-reveal/Fade"
type LoadingType = {
    setIsLoading(flag:boolean):void;
};
export default function Volunteer({setIsLoading}:LoadingType) {
    const handleImageLoaded = ()=>{
        setIsLoading(false);
    }
    return (
        <div className="volunteer">
            <div className="scroll"  id ="volunteer"></div>
            <img src="assets/volunteerImg.png" className='bg' onLoad={handleImageLoaded} alt="" />
            <div className="volunteerContent">
                <Fade bottom once={false}>
                    <h2>Become A Volunteer</h2>
                    {/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> */}
                    <div className="joinBtns">
                            <div className="donate">DONATE</div>
                            {/* <Link to="/" className="donate">DONATE</Link> */}
                    </div>
                </Fade>
            </div>
        </div>
    )
}
