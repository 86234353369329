import './salmonHome.scss'
import { Link } from 'react-router-dom';
import Fade  from "react-reveal/Fade"

type LoadingType = {
    setIsLoading(flag:boolean):void;
};
export default function SalmonHome({setIsLoading}:LoadingType) {
    const handleImageLoaded = ()=>{
        setIsLoading(false);
    }
    return (
        <div className="salmonHome" id = "salmonHome" >
            <div className="salmonHomeContent" style={{backgroundImage : `url("assets/IMG_20220109_070357_801.png")`}}>
                <div className="wrapper">
                    <Fade bottom>
                        <h1>The Collection</h1>
                        <p>The PNW Collection, are Non-Fungible Tokens (NFT’s) of salmon, inspired by indigenous art from this region. Many of the indigenous tribes in the PNW built their communities around the salmon which provided for them since their arrival here. These native peoples created beautiful art images which incorporate salmon in their oceanic and riverine environments</p>
                    </Fade>
                    <Fade right>
                        <img src="assets/SalmonPNW.png" onLoad={handleImageLoaded} alt="" /
                    >
                    </Fade>
                </div>
            </div>
        </div>
    )
}
