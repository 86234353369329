import "@ethersproject/shims"
import { BigNumber, ethers } from "ethers";
import toast from "react-hot-toast";
import { getContractObj } from ".";
import { NFTMintEngineDetail } from "./typs";

//////////////////////////////////////////  BEAR  ///////////////////////////////////////////

export async function purchaseBear(chainId, provider, account, numberOfTokens) {
    const CharityBearContract = getContractObj('CharityBear', chainId, provider);
    try {
        const ownerAddress: string = await CharityBearContract.owner();
        var nftPrice: BigNumber = BigNumber.from(0);

        if (ownerAddress.toLocaleLowerCase() === account.toLocaleLowerCase())
            nftPrice = BigNumber.from(0);
        else {
            nftPrice = await CharityBearContract.PRICE();
        }

        const tx = await CharityBearContract.purchase(numberOfTokens, {
            value: nftPrice.mul(numberOfTokens),
            gasLimit: 350000
        });
        await tx.wait(1);

        return true;
    } catch (e) {
        console.log(e);
        return false;
    }
}

export async function getBearEngineInfo(chainId, library) {
    const CharityBearContract = getContractObj('CharityBear', chainId, library);
    try {
        const [
            totalSupply,
            maxSupply,
            ownerAddress,
        ] = await Promise.all([
            CharityBearContract.totalSupply(),
            CharityBearContract.BBYTE_MAX(),
            CharityBearContract.owner(),
        ]);

        const nftMintDetail: NFTMintEngineDetail = {
            totalSupply: parseInt(totalSupply.toString()),
            maxSupply: parseInt(maxSupply.toString()),
            ownerAddress: ownerAddress.toString(),
        }

        return nftMintDetail;
    } catch (e) {
        console.log(e);
        return null;
    }
}

export async function getAccountBearNFTBalance(chainId, library, account) {
    const CharityBearContract = getContractObj('CharityBear', chainId, library);
    try {
        const NFTBalance = await CharityBearContract.balanceOf(account);
        return parseInt(NFTBalance.toString());
    } catch (e) {
        console.log(e);
        return 0;
    }
}


//////////////////////////////////////////  SALMON  ///////////////////////////////////////////

export async function purchaseSalmon(chainId, provider, account, numberOfTokens) {
    const CharitySalmonContract = getContractObj('CharitySalmon', chainId, provider);
    try {
        const ownerAddress: string = await CharitySalmonContract.owner();
        var nftPrice: BigNumber = BigNumber.from(0);

        if (ownerAddress.toLocaleLowerCase() === account.toLocaleLowerCase())
            nftPrice = BigNumber.from(0);
        else {
            nftPrice = await CharitySalmonContract.PRICE();
        }

        const tx = await CharitySalmonContract.purchase(numberOfTokens, {
            value: nftPrice.mul(numberOfTokens)
        });
        await tx.wait(1);

        return true;
    } catch (e) {
        console.log(e);
        return false;
    }
}

export async function getSalmonEngineInfo(chainId, library) {
    const CharitySalmonContract = getContractObj('CharitySalmon', chainId, library);
    try {
        const [
            totalSupply,
            maxSupply,
            ownerAddress,
        ] = await Promise.all([
            CharitySalmonContract.totalSupply(),
            CharitySalmonContract.PNW_MAX(),
            CharitySalmonContract.owner(),
        ]);

        const nftMintDetail: NFTMintEngineDetail = {
            totalSupply: parseInt(totalSupply.toString()),
            maxSupply: parseInt(maxSupply.toString()),
            ownerAddress: ownerAddress.toString(),
        }

        return nftMintDetail;
    } catch (e) {
        console.log(e);
        return null;
    }
}

export async function getAccountSalmonNFTBalance(chainId, library, account) {
    const CharitySalmonContract = getContractObj('CharitySalmon', chainId, library);
    try {
        const NFTBalance = await CharitySalmonContract.balanceOf(account);
        return parseInt(NFTBalance.toString());
    } catch (e) {
        console.log(e);
        return 0;
    }
}