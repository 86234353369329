import "./loading.scss"

export default function Loading() {
    return (
        <div className="loading">
            <img className="logoIcon" alt="" src="assets/loadingImg.png"/>
            <div className="loader">
                <div className="balls">
                    <div className="ball1"></div>
                    <div className="ball2"></div>
                    <div className="ball3"></div>
                    <div className="ball4"></div>
                    <div className="ball5"></div>
                    <div className="ball6"></div>
                    <div className="ball7"></div>
                    <div className="ball8"></div>
                </div>
            </div>
            
        </div>
    )
}
