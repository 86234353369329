import './active.scss';
import Fade  from "react-reveal/Fade"
import { Link } from 'react-router-dom';
type LoadingType = {
    setIsLoading(flag:boolean):void;
};
export default function Active({setIsLoading}:LoadingType) {
    const handleImageLoaded = ()=>{
        setIsLoading(false);
    }
    return (
        <div className="act" id="active">
            <div className="activeContent">
                <h1>active charities</h1>
                <div className="wrapper">
                    <Fade left>
                        <div className="left">
                            <div className="imgBox">
                                <Link to="/salmon" ><img src="assets/SalmonPNW.jpg" onLoad={handleImageLoaded} alt="" /></Link>
                            </div>
                            <h2>PNW</h2>
                        </div>
                    </Fade>
                    <Fade right>
                        <div className="right">
                            <div className="imgBox">
                            <Link to="/bear" ><img src="assets/Group 21411.png" alt="" /></Link>
                            </div>
                            <h2>head strong</h2>
                        </div>
                        </Fade>
                </div>
                
            </div>
        </div>
    )
}
