import './home.scss'
import { Link } from 'react-router-dom';
import Fade  from "react-reveal/Fade"

type LoadingType = {
    setIsLoading(flag:boolean):void;
};
export default function Home({setIsLoading}:LoadingType) {
    const handleImageLoaded = ()=>{
        setIsLoading(false);
    }
    return (
        <div className="home" id = "home" >
            <div className="homeContent">
                <div className="wrapper">
                    <Fade bottom>
                        <p>Raising Hope</p>
                        <h1>THE SACHIKO GROUP</h1>
                        <h2>NFT GALLERY</h2>
                        <h3>PRESENTED BY:</h3>
                        <h2>YASHADAO</h2>
                    </Fade>
                    <Fade right>
                        <img src="assets/SACHIKO-GROUP-cream-1.png" onLoad={handleImageLoaded} alt="" /
                    >
                    </Fade>
                    <Fade bottom>
                        <div className="joinBtns">
                            {/* <Link to="/" className="join" >JOIN NOW</Link>
                            <Link to="/" className="donate">DONATE</Link> */}
                            <div className="join" >JOIN NOW</div>
                            <div className="donate">DONATE</div>
                        </div>
                    </Fade>
                </div>
            </div>
        </div>
    )
}
