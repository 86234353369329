import './whitepaper.scss'
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { Worker, Viewer} from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import Menu from '../menu/Menu';
import { useState } from 'react';
import Topbar from 'components/topbar/Topbar';

export default function Whitepaper() {
    
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    const [menuOpen, setMenuOpen] = useState(false);
    return (
        <div className="whitepaper">
            <Topbar menuOpen = {menuOpen} setMenuOpen = {setMenuOpen}/>
            <Menu menuOpen = {menuOpen} setMenuOpen = {setMenuOpen}/>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
            <div className='pdfContent' >
                <Viewer fileUrl="assets/Salmon_Whitepaper.pdf" plugins={[defaultLayoutPluginInstance]} />
            </div>
            </Worker>
        </div>
        
    )
}
