import { Contract } from '@ethersproject/contracts'
import CharityBearABI from 'contracts/CharityBear.json'
import CharitySalmonABI from 'contracts/CharitySalmon.json'

export const Networks = {
  ETHMainNet: 1,
  RinkerByTestNet: 4,
}

export const CONTRACTS_BY_NETWORK = {
  [Networks.ETHMainNet]: {
    CharityBear: {
      address: '0x5075a047f3D642beCE60DA8390C70FEC7f6C5807',
      abi: CharityBearABI,
    },
    CharitySalmon: {
      address: '0x3b17D0e7F90795f55C14D2e4f6CE49F36FA45926',
      abi: CharitySalmonABI,
    }
  },
  [Networks.RinkerByTestNet]: {
    CharityBear: {
      address: '0x9f968Ca687dDE20F61330569e7775E4CC1b91349',
      abi: CharityBearABI,
    },
    CharitySalmon: {
      address: '0x656B9E24de2e41a94A7dBbaeb3937777Cf34E448',
      abi: CharitySalmonABI,
    }
  }
}

export const currentNetwork = process.env.REACT_APP_NETWORK_ID;

export const baseApiUrl = process.env.REACT_APP_API_URL;

export function getContractInfo(name, chainId = null) {
  if (!chainId) chainId = currentNetwork;

  const contracts = CONTRACTS_BY_NETWORK?.[chainId];
  if (contracts) {
    return contracts?.[name];
  } else {
    return null;
  }
}

export function truncateWalletString(walletAddress) {
  if (!walletAddress) return walletAddress;
  const lengthStr = walletAddress.length;
  const startStr = walletAddress.substring(0, 7);
  const endStr = walletAddress.substring(lengthStr - 7, lengthStr);
  return startStr + '...' + endStr;
}

export function truncateHashString(txhash) {
  if (!txhash) return txhash;
  const lengthStr = txhash.length;
  const startStr = txhash.substring(0, 10);
  const endStr = txhash.substring(lengthStr - 10, lengthStr);
  return startStr + '...' + endStr;
}

export function getContractObj(name, chainId, provider) {
  const info = getContractInfo(name, chainId);
  return !!info && new Contract(info.address, info.abi, provider);
}

export function getContractObjWithAddress(name, chainId, provider, contractAddress) {
  const info = getContractInfo(name, chainId);
  return !!info && new Contract(contractAddress, info.abi, provider);
}

export const shorter = (str) =>
  str?.length > 8 ? str.slice(0, 6) + '...' + str.slice(-4) : str
