import { useWeb3React } from '@web3-react/core';
import BearHome from 'components/bearHome/BearHome';
import BearMint from 'components/bearMint/BearMint';
import BearRoadmap from 'components/bearRoadmap/BearRoadmap';
import BearSections from 'components/bearSections/BearSections';
import BearTopbar from 'components/bearTopbar/BearTopbar';
import Footer from 'components/footer/Footer';
import Loading from 'components/loading/Loading';
import Menu from 'components/menu/Menu'
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import './bearPage.scss'

export default function BearPage() {
    const [menuOpen, setMenuOpen] = useState(false);

    const [isHBearomeLoading, setIsBearHomeLoading] = useState(true);
    const [isBearSectionsLoading, setIsBearSectionsLoading] = useState(true);
    const [isBearMintLoading, setIsBearMintLoading] = useState(false);

    const isTabletOrMobile = useMediaQuery({
        query: "screen and (max-width: 768px) and (orientation:portrait)",
      });
      const isLandOrMobile = useMediaQuery({
        query: "screen and (max-height: 768px) and (orientation:landscape)",
      });
    const [sectionWidth, setSectionWidth] = useState(0);
    const [sectionHeight, setSectionHeight] = useState("0vh");
    const [loadingWidth, setLoadingWidth] = useState(0);
    const [loadingHeight, setLoadingHeight] = useState(0);

    useEffect(() => {
        if (isHBearomeLoading || isBearSectionsLoading || isBearMintLoading) {
            setLoadingWidth(100)
            setLoadingHeight(100)

            setSectionWidth(0);
            setSectionHeight("0vh")
        }
        else{
            setLoadingWidth(0)
            setLoadingHeight(0)

            setSectionWidth(100);
            setSectionHeight("auto")
        }
        // menu effect
        if (isTabletOrMobile) {
        }
    
        if (isLandOrMobile) {
        }
        if (!isLandOrMobile && !isTabletOrMobile) {
            setMenuOpen(false);
        }

    }, [isHBearomeLoading, isBearSectionsLoading, isBearMintLoading, isTabletOrMobile, isLandOrMobile ]);
    return (
        <div className='bearPage'>
            {loadingWidth === 0 &&<BearTopbar menuOpen = {menuOpen} setMenuOpen = {setMenuOpen}/>}
            <Menu menuOpen = {menuOpen} setMenuOpen = {setMenuOpen}/>
            <div className="loding" style = {{width: loadingWidth + "%", height: loadingHeight + "vh", display: loadingWidth === 0? 'none':'flex'}}>
                <Loading/>
            </div>
            <div className="sections" style = {{width: sectionWidth + "%", height: sectionHeight, backgroundImage:'url("assets/snowBG.png")'}}>
                <BearHome setIsLoading = {setIsBearHomeLoading}/>
                <BearSections setIsLoading = {setIsBearSectionsLoading}/>
                <BearRoadmap/>
                <BearMint setIsLoading = {setIsBearMintLoading}/>
                <Footer/>
            </div>
        </div>
    )
}
