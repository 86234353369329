import './bearRoadmap.scss'
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import Fade  from "react-reveal/Fade"
export default function BearRoadmap() {

    const roadmapData = [
        {
            id : 0,
            title : 'PHASE 1',
            txt : 'Run a pilot with borrowed gear to collect data and iron out any teething issues. (COMPLETED)'
        },
        {
            id : 1,
            title : 'PHASE 2',
            txt : 'Purchase more equipment to cater to more students'
        },
        {
            id : 2,
            title : 'PHASE 3',
            txt : "Rent or purchase our own facilities and open as a YOUTH focused gym with general public access and membership."
        },
        {
            id : 3,
            title : 'PHASE 4',
            txt : 'Develop a ‘Meta-Suit’, including computers and staff to enable classes, guest lectures and training sessions for youth to learn how they can use the Metaverse and more.'
        },
        {
            id : 4,
            title : 'BIGGER PICTURE',
            txt : "Expansion to creating ‘YOUTH CITY’ hubs that are replicated throughout the country and eventually globally. Inviting guest speakers to train young people and adults a like, making huge changes in the local communities. HeadStrong is a section of YOUTH CITY."
        },
    ]
    const isTabletOrMobile = useMediaQuery({ query: 'screen and (max-width: 768px) and (orientation:portrait)' })
    const isLandOrMobile = useMediaQuery({ query: 'screen and (max-height: 768px) and (orientation:landscape)' })

    useEffect(() => {
        if (isTabletOrMobile){
        }
        
        if (isLandOrMobile){
        }
        if (!isTabletOrMobile && !isLandOrMobile){
        }
        });
    return (
        <div className="bearRoadmap" id ="roadmap">
            <div className="roadmapTitle">
                <h1>How will the funds be used?</h1>
            </div>
            <div className="roadmapContent">
            <div className="verticalLine">
            </div>
                {roadmapData.map((d)=>(
                    <div className="node" key = {d.id}>
                        <div className="left">
                            <div className="circle"><div className="circleAni"></div></div>
                        </div>
                        <div className="right">
                            <h1>{d.title}</h1>
                            <p> - {d.txt}</p>
                        </div>
                    </div>
                ))}
            </div>
            
        </div>
    )
}
