import './bearHome.scss'
import { Link } from 'react-router-dom';
import Fade  from "react-reveal/Fade"

type LoadingType = {
    setIsLoading(flag:boolean):void;
};
export default function BearHome({setIsLoading}:LoadingType) {
    const handleImageLoaded = ()=>{
        setIsLoading(false);
    }
    return (
        <div className="bearHome" id = "bearHome" >
            <div className="bearHomeContent">
                <div className="wrapper">
                    <Fade bottom>
                        <h1>Together, we can do something.</h1>
                        <p><span>BearByte</span> is an NFT (Non-Fungible Token) that has been created in an effort to bring awareness, support and funds towards the charitable work of <span>HeadStrong,</span> a charity founded in the UK to help young men tackle those life-threatening challenges.</p>
                        <p>We chose a bear to represent the <span>family</span> we want to build, the loyalty we have to our communities and the <span>strength</span> every person has, that can help grow a deep and wide community full of people who are physically and mentally strong, committed to each other and ready to take on the world.</p>
                    </Fade>
                    <Fade right>
                        <img src="assets/bearLogo.png" onLoad={handleImageLoaded} alt="" /
                    >
                    </Fade>
                </div>
            </div>
        </div>
    )
}
