import './salmonSections.scss'
import { Link } from 'react-router-dom';
import Fade  from "react-reveal/Fade"

type LoadingType = {
    setIsLoading(flag:boolean):void;
};
export default function SalmonSections({setIsLoading}:LoadingType) {
    const handleImageLoaded = ()=>{
        setIsLoading(false);
    }
    return (
        <div className="salmonSections" id = "salmonSections" >
            <div className="salmonSectionsContent">
                <div className="wrapper">
                    <Fade left>
                        <div className="img">
                            <img src="assets/IMG_20220109_070404_656.png" onLoad={handleImageLoaded}  alt="" />
                        </div>
                    </Fade>
                    <Fade right>
                        <div className="text">
                            <h1>This Must Change.</h1>
                            <p><span>More than 40%</span> of the spawning and rearing habitat once available to salmon and steelhead in the Columbia River Basin is <span>permanently blocked</span> by dams.</p>
                            <p>This means that alone <span>less than 60%</span> is available and much of that habitat is degraded, this is where we would like your help in making a difference.</p>

                        </div>
                    </Fade>
                </div>
                <div className="wrapper">
                    <Fade left>
                        <div className="text">
                            <h1>Tell me about the Pacific salmon…</h1>
                            <p>This collection will focus on salmon of the <span>Pacific Northwest,</span> salmon are depicted in tribal and modern art throughout the region. The salmon are honored and revered by the indigenous culture and other people who inhabit the region today. Pacific salmon are anadromous, meaning that they reproduce in inland freshwater systems, and the juvenile fish migrate to the ocean, where they gain more than 99 per cent of their adult weight. Once these juveniles are adults and are ready to reproduce, they migrate to the streams and rivers in which they were born, to spawn and produce more juvenile fish. Pacific salmon are also semelparous, this means that most adults die after reproduction and become nutrients and food in the inland freshwater systems. <span>These stream systems, that contain spawning salmon in the Pacific Northwest, are considered an ecological nutrient backbone.</span></p>

                        </div>
                    </Fade>
                    <Fade right>
                        <div className="img">
                            <img src="assets/IMG_20220109_070410_127.png" alt="" />
                        </div>
                    </Fade>
                </div>
                <div className="wrapper">
                    <Fade left>
                        <div className="img">
                            <img src="assets/IMG_20220109_070407_380.png" alt="" />
                        </div>
                    </Fade>
                    <Fade right>
                        <div className="text">
                            <h1>The Collection</h1>
                            <p>The <span>PNW Collection</span>, are Non-Fungible Tokens (NFT’s) of salmon, inspired by indigenous art from this region. Many of the indigenous tribes in the PNW built their communities around the salmon which provided for them since their arrival here. These native peoples created beautiful art images which incorporate salmon in their oceanic and riverine environments.</p>
                            <p>This art is created to honor the indigenous art of the region and to bring awareness, support and funding towards the charitable work of nonprofits in the PNW in <span>protecting and conserving salmon.</span> The funds will be used for the establishment and rehabilitation of <span>natural spawning and rearing habitat for anadromous fish.</span> We choose the spawning phase of a pacific salmon because the upriver salmon migration is one of nature's most exciting dramas.</p>
                            <p>There are five species of Pacific salmon (chinook, chum, coho, pink, and sockeye) who make a long and strenuous journey, in a desperate race against time, to reach their natal spawning grounds. Every obstacle and degraded habitat takes its toll on the ability of these fish to spawn and produce viable offspring.</p>

                        </div>
                    </Fade>
                </div>
                <div className="wrapper">
                    <Fade left>
                        <div className="text">
                            <h1>How will these funds be used?</h1>
                            <p>The funds generated from this project will be used for <span>protection</span> and <span>establishment of anadromous fish habitat</span> and the <span>removal of barriers</span> that limit salmon access to important stream habitat within the Pacific Northwest region.</p>
                            <p>These funds will directly make an impact on the management of these important freshwater systems which will help these native anadromous species <span>thrive</span> for generations to come.</p>
                        </div>
                    </Fade>
                    <Fade right>
                        <div className="img">
                            <img src="assets/IMG_20220109_070402_175.png" alt="" />
                        </div>
                    </Fade>
                </div>
            </div>

            <div className="salmonSectionsContent_mob">
            <div className="wrapper">
                    <Fade left>
                        <div className="img">
                            <h1>This Must Change.</h1>
                            <img src="assets/IMG_20220109_070404_656.png" onLoad={handleImageLoaded}  alt="" />
                        </div>
                    </Fade>
                    <Fade right>
                        <div className="text">
                            <p><span>More than 40%</span> of the spawning and rearing habitat once available to salmon and steelhead in the Columbia River Basin is <span>permanently blocked</span> by dams.</p>
                            <p>This means that alone <span>less than 60%</span> is available and much of that habitat is degraded, this is where we would like your help in making a difference.</p>

                        </div>
                    </Fade>
                </div>
                <div className="wrapper">
                    <Fade left>
                        <div className="img">
                            <h1>Tell me about the Pacific salmon…</h1>
                            <img src="assets/IMG_20220109_070410_127.png" alt="" />
                            

                        </div>
                    </Fade>
                    <Fade right>
                        <div className="text">
                        <p>This collection will focus on salmon of the <span>Pacific Northwest,</span> salmon are depicted in tribal and modern art throughout the region. The salmon are honored and revered by the indigenous culture and other people who inhabit the region today. Pacific salmon are anadromous, meaning that they reproduce in inland freshwater systems, and the juvenile fish migrate to the ocean, where they gain more than 99 per cent of their adult weight. Once these juveniles are adults and are ready to reproduce, they migrate to the streams and rivers in which they were born, to spawn and produce more juvenile fish. Pacific salmon are also semelparous, this means that most adults die after reproduction and become nutrients and food in the inland freshwater systems. <span>These stream systems, that contain spawning salmon in the Pacific Northwest, are considered an ecological nutrient backbone.</span></p>
                        </div>
                    </Fade>
                </div>
                <div className="wrapper">
                    <Fade left>
                        <div className="img">
                            <h1>The Collection</h1>
                            <img src="assets/IMG_20220109_070407_380.png" alt="" />
                        </div>
                    </Fade>
                    <Fade right>
                        <div className="text">
                            <p>The <span>PNW Collection</span>, are Non-Fungible Tokens (NFT’s) of salmon, inspired by indigenous art from this region. Many of the indigenous tribes in the PNW built their communities around the salmon which provided for them since their arrival here. These native peoples created beautiful art images which incorporate salmon in their oceanic and riverine environments.</p>
                            <p>This art is created to honor the indigenous art of the region and to bring awareness, support and funding towards the charitable work of nonprofits in the PNW in <span>protecting and conserving salmon.</span> The funds will be used for the establishment and rehabilitation of <span>natural spawning and rearing habitat for anadromous fish.</span> We choose the spawning phase of a pacific salmon because the upriver salmon migration is one of nature's most exciting dramas.</p>
                            <p>There are five species of Pacific salmon (chinook, chum, coho, pink, and sockeye) who make a long and strenuous journey, in a desperate race against time, to reach their natal spawning grounds. Every obstacle and degraded habitat takes its toll on the ability of these fish to spawn and produce viable offspring.</p>

                        </div>
                    </Fade>
                </div>
                <div className="wrapper">
                    <Fade left>
                        <div className="img">
                            <h1>How will these funds be used?</h1>
                            <img src="assets/IMG_20220109_070402_175.png" alt="" />
                            
                        </div>
                    </Fade>
                    <Fade right>
                        <div className="text">
                        <p>The funds generated from this project will be used for <span>protection</span> and <span>establishment of anadromous fish habitat</span> and the <span>removal of barriers</span> that limit salmon access to important stream habitat within the Pacific Northwest region.</p>
                            <p>These funds will directly make an impact on the management of these important freshwater systems which will help these native anadromous species <span>thrive</span> for generations to come.</p>
                        </div>
                    </Fade>
                </div>
            </div>
        </div>
    )
}
