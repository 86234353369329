import './about.scss'
import Fade  from "react-reveal/Fade"
type LoadingType = {
    setIsLoading(flag:boolean):void;
};

export default function About() {
    // export default function About({setIsLoading}:LoadingType) {
    // const handleImageLoaded = ()=>{
    //     setIsLoading(false);
    // }
    return (
        <div className="about">
            <div className="scroll" id="about"></div>
            <div className="aboutContent">
                <Fade bottom>
                    <h2>INTRODUCTION:</h2>
                    <p><span>The SACHIKO GROUP</span> Gallery is on a mission to give to nonprofits in need from around the world.</p>
                    
                    <p>This Project aims to highlight conservation efforts and charitable topics from many regions of planet Earth by introducing specifically chosen art pieces from those regions.</p>
                    <p>We want to share these phenomenal pieces of art with the crypto community! And <span>GIVE BACK</span> to each of these regions causes!</p>
                    <p>The majority of the <span>NFT</span> proceeds will be donated to charities associated with each different collection with the aim to preserve, conserve, and restore important habitats around the world.</p>
                </Fade>
                
                    {/* <h1>#1. THE PACIFIC NORTHWEST</h1>
                    <div className="wrapper">
                        <Fade left>
                        <div className="left">
                            <img src="assets/c439fc097e4c0336ce7c09d157c5f3a3.png"  onLoad={handleImageLoaded} alt="" />
                        </div>
                        </Fade>
                        <Fade right>
                        <div className="right">
                            <p>The very first of our collections will feature art from The Pacific Northwest Indigenous Peoples.</p>
                            <p>These people have created beautiful pieces of artwork that incorporate salmon, the river and oceanic environments inspired by  the regions they inhabit, and to pay tribute to the animals and people that have come to rely on the salmon for their existence.</p>
                            <p>The cultural connection behind these art pieces are striking and multifaceted in their design and meanings.</p>
                            <p>There is a rich history and many cultural beliefs associated with the art presented. We are here to introduce these beautiful pieces to the community and to raise awareness about ecological needs in this region.</p>
                        </div>
                        </Fade>
                    </div> */}
               
                
            </div>
        </div>
    )
}
