import { useWeb3React } from '@web3-react/core';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { getSalmonEngineInfo, purchaseSalmon } from 'utils/contracts';
import { NFTMintEngineDetail } from 'utils/typs';
import Timer from '../timer/Timer';
import './salmonMint.scss'
type LoadingType = {
    setIsLoading(flag: boolean): void;
};

export default function SalmonMint({ setIsLoading }: LoadingType) {
    const [showMint, setShowMint] = useState(false)
    const [mintCount, setMintCount] = useState(1);
    const decreaseHandle = () => {
        if (mintCount > 1) {
            setMintCount(mintCount - 1)
        }

    }
    const increaseHandle = () => {
        if (mintCount < 10) {
            setMintCount(mintCount + 1)
        }
    }

    const [loginStatus, setLoginStatus] = useState(false);
    const { connector, library, chainId, account, active } = useWeb3React();
    const [mintEngineDetail, setMintEngineDetail] = useState<NFTMintEngineDetail>(null);

    useEffect(() => {
        const isLoggedin = account && active && chainId === parseInt(process.env.REACT_APP_NETWORK_ID, 10);
        setLoginStatus(isLoggedin);
        getSalmonEngineInfo(chainId, library).then(
            (nftMintEngineDetail: NFTMintEngineDetail) => {
                setMintEngineDetail(nftMintEngineDetail);
            }
        );

    }, [connector, library, account, active, chainId]);

    const handleImageLoaded = () => {
        setIsLoading(false);
    }



    const mintTokens = async () => {
        if (!loginStatus) {
            toast.error("Please connect wallet correctly!");
            return;
        }

        if (mintCount <= 0) {
            toast.error("Mint Count should be over than 0");
            return;
        }
        const load_toast_id = toast.loading("Please wait for Mint...");
        try {
            const bSuccess = await purchaseSalmon(chainId, library.getSigner(), account, mintCount);
            if (bSuccess) {
                toast.success("Mint Success!");
            } else {
                toast.error("Mint Failed!");
            }
        } catch (error) {
            toast.error("Mint Failed!");
        }
        toast.dismiss(load_toast_id);
    };

    return (
        <div className="salmonMint">
            <div className="mintContent">
                {showMint === true ?
                    <div className="mintWrapper">
                        <div className="mintLeft">
                            <img src="assets/SalmonPNW.png" onLoad={handleImageLoaded} alt="" />
                        </div>
                        <div className="mintRight">
                            <div className="top">
                                <h2>Salmon Tokenomics</h2>
                                {
                                    mintEngineDetail && <h3>Minted {mintEngineDetail.totalSupply} / {mintEngineDetail.maxSupply}</h3>
                                } 
                            </div>
                            <h1>Mint Salmon</h1>
                            <div className="mintCount">
                                <button className="mintDecrease" onClick={decreaseHandle}><i className="fas fa-minus"></i></button>
                                <span className="mintCountValue">{mintCount}</span>
                                <button className="mintIncrease" onClick={increaseHandle} ><i className="fas fa-plus"></i></button>
                                <button className="mintBtn" onClick={mintTokens}>MINT</button>
                            </div>
                            <p>Price : 0.07 ETH</p>
                        </div>
                    </div> :
                    <>
                        <h1 className="mintTitle">Mint Salmon Tokenomics</h1>
                        <div className="countDown">
                            <p>January 30, 2022</p>
                            {/* <Timer setShowMint={setShowMint} deadLine={0} /> */}
                            <Timer setShowMint={setShowMint} deadLine={1643572800} />
                        </div>
                    </>
                }
                <p><strong>PNW</strong> will be available from January 30, 2022 from the Yasha Launchpad </p>
            </div>

        </div>
    )
}

